.dashboard {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);

  .row {
    max-width: 1000px;
    min-width: 400px;
  }
}
