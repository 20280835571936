.venue {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);

  &.card {
    max-width: 1000px;
    min-width: 800px;
  }

  .card-footer,
  .action {
    text-align: right;
  }

  .date .input-group-prepend {
    width: 75px;
  }

  .roomtype,
  .flatfee {
    font-size: .8rem;
    margin-left: 0;
    padding-top: 1em;
    width: 100%;

    &.row {
      border: 1px solid #DDDDDD;
      border-collapse: collapse;
      margin-top: -1px;
    }

    &.odd {
      background-color: #E0F0FF;
    }

    &.even {
      background-color: white;
    }

    .input-group-text, input {
      font-size: .8rem;
    }

    .roomtype-index,
    .flatfee-index {
      text-align: center;
    }

    .roomtype-type,
    .flatfee-type {
      .input-group-prepend {
        width: 60px;
      }
    }

    .roomtype-desc,
    .flatfee-desc {
      .input-group-prepend {
        width: 60px;
      }
    }


    .roomtype-beds,
    .flatfee-price {
      .input-group-prepend {
        width: 60px;
      }
    }

    .roomtype-count {
      .input-group-prepend {
        width: 60px;
      }
    }

  }

  button.add-roomtype,
  button.add-flatfee {
    margin-top: 1em;
  }

  .vr {
    border-left: .1px solid #DDDDDD;
    margin-left: -.5px;
    margin-right: -.5px;
  }

  hr {
    border: 0;
    border-top: .1px solid #DDDDDD;
  }
}