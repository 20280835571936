@import '../theme/theme.scss';

.logo {
  display: inline;
  width: 30px;
  height: 30px;
  margin-left: .5rem;
  margin-right: .5rem;
}

.nav-header {
  font-size: .8rem;
  padding: 1rem;

  h5 {
    display: inline;
    position: relative;
    top: .3rem;
  }

  .dropdown-divider {
    border-top-color: rgba(0, 0, 0, 0.15);
  }

  .nav-link {
    color: black;

    &.active {
      color: black !important;
      font-weight: bold !important;
      background-color: rgba(0, 0, 0, 0.15) !important;
    }

  }
}

.show .dropdown-toggle {
  color: black !important;
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.dropdown-menu {
  font-size: .8rem !important;
  background-color: $header-bg !important;

  & .disabled {
    color: grey !important
  }
}
