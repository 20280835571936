$white: #fff;
$shadow-black: rgba(0, 0, 0, .30);


.text-sub {
  font-size: .7rem;
}

.applicants {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);

  &.card {
    
    min-width: 800px;
  }

  .table {
    font-size: .8rem;
    overflow: scroll;

    .actions-column {
      white-space: nowrap;
    }
  }

  .dropdown-toggle.btn {
    font-size: .7rem;
  }

  .dropdown-item.active {
    background-color: $shadow-black;

    > .dropdown-item-text {
      color: $white !important;
    }
  }
}
