@import '../theme/theme';

.eventbody {
  font-size: .7rem !important;
}

.vertical-timeline-element-title {
  font-size: 1.4rem !important;
}

.vertical-timeline-element-subtitle {
  font-size: 1rem !important;
}
