.room {
  margin-bottom: 10px;
  padding: 2px;
  width: 100%;
}

.room-selection {
  margin-bottom: 5px; 
  :hover {
    background-color: #f8f9fa;
  }
}
 
.dropdown-header-divider {
  font-weight: bold;
  font-size: 1.2em;
  color: #fff !important;
}