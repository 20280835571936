@import '../theme/theme';

$shadow-black: rgba(0, 0, 0, .30);


.text-sub {
  font-size: .7rem;
}

.processes {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);

  &.card {
    min-width: 400px;
  }

  .card-body {
    overflow: scroll;
  }

  .table {
    font-size: .7rem;

    td {
      white-space: nowrap;

    }

    tr {
      width: auto;

      &:hover {
        background-color: $table-highlight;

      & .text-info {
          color: $white !important;
        }
      }
    }


  }

  .dropdown-toggle.btn {
    font-size: .7rem;
  }

  .dropdown-item.active {
    background-color: $shadow-black;

    > .dropdown-item-text {
      color: $white !important;
    }
  }
}

.actiontable {
  font-size: .7rem;
  & td {
    padding: .5rem !important;
  }
}
