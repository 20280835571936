.templates {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);

  .text-sub.btn {
    font-size: .7rem;
  }

  .card {
    max-width: 1000px;
    min-width: 800px;
  }

  .dropdown-toggle.btn {
    font-size: .7rem;
  }

  .dropdown-item.active {
    background-color: rgba(0, 0, 0, .30);

    > .dropdown-item-text {
      color: white !important;
    }
  }

  .newsletter-title {
    max-width: 100px;
  }

  .newsletter-subject {
    max-width: 200px;
  }

  .newsletter-text {
    max-width: 400px;
  }
}

.modal-dialog {
  margin-top: 0 !important;
  top: 50%;
  transform: translateY(-50%) !important;
}
