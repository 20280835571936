$white: #fff;
$shadow-black: rgba(0, 0, 0, .30);


.text-sub {
  font-size: .7rem;
}

.attendees {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);

  &.card {
    min-width: 400px;
  }

  .card-body {
    overflow: scroll;
  }

  .table {
    font-size: .8rem;

    td {
      white-space: nowrap;
    }

    tr {
      width: auto;
    }
  }

  .dropdown-toggle.btn {
    font-size: .7rem;
  }

  .dropdown-item.active {
    background-color: $shadow-black;

    > .dropdown-item-text {
      color: $white !important;
    }
  }
}
