.lottery {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);

  .row {
    max-width: 1000px;
    min-width: 400px;
  }

  .beds {
    .input-group-prepend {
      width: 75%;

      .label {
        width: 100%;
      }
    }
  }

  .bookings {
    width: 100%;
  }
}
