@import './colors';
@import '../../node_modules/bootstrap/scss/bootstrap';

body {
  background-color: $body-bg;
  font-family: 'PT Serif Caption', 'Times New Roman', serif;
  font-size: 1em;
}

:root {
  --amplify-font-family: 'PT Serif Caption', 'Times New Roman', serif;

}

.center {
  left: 50%;
  position: absolute;
  top: 50%;
  -ms-transform: translate(-25%, -50%);
  transform: translate(-25%, -50%);
}

.custom-switch {
  padding-left: 1rem;
}

.btn-info-light {
  background-color: #26cfd8 !important;
}


.text-warning {
  color: $warning;
}
.text-info {
  color: $green;
}
.text-danger {
  color: $danger;
}
