.send {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  &.card {
    min-width: 800px;
    max-width: 1000px;
  }

}