.text-sub {
  font-size: .7rem;
}

.subscribers {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  &.card {
    min-width: 800px;
    max-width: 1000px;
  }

  & .dropdown-toggle.btn {
    font-size: .7rem;
  }

  & .dropdown-item.active {
    background-color: rgba(0,0,0,.30);

    &>.dropdown-item-text {
      color: white !important;
    }
  }
}

