
.subnav {
  font-size: .8rem;
  color: #17a2b8;
  background: black;

  & a.text-info:hover,
  & a.text-info.active {
    color: white !important;
  }

  & .dropdown-toggle {
    color: #17a2b8 !important;
  }

  & .show .dropdown-toggle {
    color: #17a2b8 !important;
  }

  & .dropdown-menu {
    border-top-color: rgba(255,255,255,.15) !important;
    background-color: black !important;
  }

  & .dropdown-item.bg-black {
    background-color: black;
    &:hover,
    &.active {
      background-color: rgba(255,255,255,.15) !important;
    }
  }
}